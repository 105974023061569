import React, {Component} from "react";
import { Table } from "react-bootstrap";

import "./SeasonScoresTableDumb.css";

export default class SeasonScoresTableDumb extends Component {

  scoringColorPartitions() {
    var playerNames = this.props.playerNames;
    var playerScores = this.props.playerScores;

    var allScores = []
    for (var i = 0; i < playerNames.length; i++) {
      allScores = allScores.concat(playerScores[playerNames[i]])
    }
    allScores = allScores.sort(this.sortNumber);
    var minScore = allScores[0];
    var maxScore = allScores[allScores.length-1];
    return this.getPartitions(minScore, maxScore);
  }

  getPartitions(min, max) {
    var partitions = 9;
    var diff = max - min;
    var bucketSize = diff/partitions;

    var partitionsList = [];
    for(var i = 1; i < partitions; i++) {
      partitionsList.push(min + (bucketSize*i))
    }
    return partitionsList
  }

  getColorClassFromPartitions(partitions, value) {
    if (value === "") {
      return "score-none";
    }

    var classes = ["score-ebad", "score-vbad", "score-pbad", "score-bad", "score-ok", "score-good", "score-pgood", "score-vgood", "score-egood"];
    for (var i = 0; i < partitions.length; i++) {
      if (value < partitions[i]) {
        return classes[i];
      }
    }
    return classes[classes.length-1]
  }

  sortNumber(a, b) {
    return a - b;
  }

  tableHeaders() {
    var headers = [<td key="emptyweek"></td>];
    for(var i = 0; i < this.props.weeksCount; i++){
      headers.push(<td className="score-table-heading" key={`week${i}`}>Week {i+1}</td>)
    }
    return headers
  }
 
  tableRows(partitions){
    var rows = [];
    var playerNames = this.props.playerNames;
    for(var i = 0; i < playerNames.length; i++){
      rows.push(
        <tr key={`playerNameRow${i}_${playerNames[i]}`}>
          <td key={`playerName_${i}`}>
            {playerNames[i]}
          </td>
          {this.playerWeeksRow(partitions, playerNames[i])}
        </tr>
      )
    }
    return rows
  }

  playerWeeksRow(partitions, playerName) {
    var cols = [];
    for (var i = 1; i <= this.props.playerScores[playerName].length; i++) {
      var value;
      if (this.props.playerScores[playerName][i-1] == null) {
        value = "";
      }
      else {
        value = this.props.playerScores[playerName][i-1]
      }
      cols.push(
        <td key={`${playerName}_week${i}`} className={this.getColorClassFromPartitions(partitions, value)}>
          {value}
        </td>
      )
    }
    return cols;
  }

  render(){
    var partitions = this.scoringColorPartitions()
    return (
      <div>
        <h2>Scores</h2>
        <Table responsive>
          <thead>
            <tr>
              {this.tableHeaders()}
            </tr>
          </thead>
          <tbody>
            {this.tableRows(partitions)}
          </tbody>
        </Table>
      </div>
    );
  }
}
