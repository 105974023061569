import React from "react";
import { Route, Redirect } from "react-router-dom";
import withAuth from '../contexts/withAuth'

const AuthenticatedRoute = ({ component: C, props: cProps, ...rest }) =>
  <Route
    {...rest}
    render={props => {
      return rest.isAuthenticated()
        ? <C {...props} {...cProps} />
        : <Redirect
          to={`/login?redirect=${props.location.pathname}${props.location
            .search}`}
        />}
    }

  />;

export default withAuth(AuthenticatedRoute, ['isAuthenticated'])