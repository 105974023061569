import React, { Component } from "react";
import Plot from 'react-plotly.js';

export default class RankingsPlot extends Component {

  getListOfCompletedWeeks(maxNum) {
    var list = [];
    for (var j = 1; j <= maxNum; j++) {
      list.push(j);
    }
    return list;
  }

  getTracesFromStandings(playerNames, standings, completedWeeksCount) {
    var traces = [];
    for (var i = 0; i < playerNames.length; i++) {
      var name = playerNames[i];
      var trace = {}

      trace['x'] = this.getListOfCompletedWeeks(completedWeeksCount)
      trace['y'] = standings[name].rankHistory;
      trace['mode'] = 'lines+markers';
      trace['name'] = name;
      trace['type'] = 'scatter';
      traces.push(trace);
    }
    return traces;
  }

  getLayout(playerNames, completedWeeksCount) {
    if (playerNames === undefined || playerNames.length === 0) {
      return;
    }
    var weeks = this.getListOfCompletedWeeks(completedWeeksCount)
    var layout = {
      title: 'League Ranking', 
      xaxis:{
        title:'Weeks',
        tickvals: weeks
      }, 
      yaxis:{title:'Rank', range: [playerNames.length+1, 0], zeroline: false},
      autosize: true,
    }
    return layout
  }

  getStyle() {
    return {
      width: '100%'
    }
  }

  render() {
    return (<Plot 
      data={this.getTracesFromStandings(this.props.playerNames, this.props.standings, this.props.completedWeeksCount)}
      layout={this.getLayout(this.props.playerNames, this.props.completedWeeksCount)} useResizeHandler={true}
      style={this.getStyle()}/>)
  }
}