import React, { Component } from "react";
import Plot from 'react-plotly.js';

export default class PowerRankingsPlot extends Component {

  getTraceFromStandings(playerNames, standings) {
    if (playerNames === undefined || playerNames.length === 0) {
      return;
    }
    var yValues = [];
    for (var i = 0; i < playerNames.length; i++) {
      var name = playerNames[i];
      yValues.push(parseFloat(standings[name]['powerRank']).toFixed(1));
    }

    var trace = {
      x: playerNames,
      y: yValues,
      type: 'bar'
    }
    return [trace];
  }

  getLayout(playerNames) {
    if (playerNames === undefined || playerNames.length === 0) {
      return;
    }
    var layout = {
      title: 'Power Rankings', 
      xaxis: playerNames, 
      yaxis:{title:'Power Rank'},
      autosize: true,
    }
    return layout
  }

  getStyle() {
    return {
      width: '100%'
    }
  }

  render() {
    return (<Plot 
      data={this.getTraceFromStandings(this.props.playerNames, this.props.standings)}
      layout={this.getLayout(this.props.playerNames)}
      useResizeHandler={true} style={this.getStyle()}/>)
  }
}