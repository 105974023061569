import { API } from "aws-amplify"
import React, {Component} from "react";
import ls from 'local-storage'
import { Table, FormGroup, FormControl, PageHeader } from "react-bootstrap";
import withAuth from '../contexts/withAuth'
import LoaderButton from "../components/LoaderButton";

import "./LoaderButton.css";
import "./MatchupTable.css"
class MatchupTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leagueId: props.leagueId,
      seasonId: props.seasonId,
      playerCount: props.playerCount,
      playerNames: props.playerNames,
      weeksCount: props.weeksCount,
      matchupOptions: this.initializeWeeklyPlayerOptions(props.playerNames, props.weeksCount),
      matchups: this.initializeEmptyMatchups(props.playerNames, props.weeksCount),
      isLoading: false
    };
  }

  initializeWeeklyPlayerOptions(names, weeksCount) {
    var playersWeeks=[];
    for(var i = 0; i < weeksCount; i++){
      var names_copy = [...names]
      playersWeeks.push(names_copy)
    }
    return playersWeeks
  }

  initializeEmptyMatchups(playerNames, weeksCount) {
    var name;
    var matchups = new Array(weeksCount);
    for(var weekIndex = 0; weekIndex < weeksCount; weekIndex++) {
      matchups[weekIndex] = {}
      for(var nameIndex = 0; nameIndex < playerNames.length; nameIndex++) {
        name = playerNames[nameIndex];
        matchups[weekIndex][name] = "";
      }
    }
    return matchups;
  }

  tableHeaders() {
    var headers = [<td key="emptyweek"></td>];
    for(var i = 0; i < this.state.weeksCount; i++){
      headers.push(<td key={`week${i}`}>Week {i+1}</td>)
    }
    return headers
  }

  tableRows(){
    var rows = [];
    for(var i = 0; i < this.state.playerCount; i++){
      rows.push(
        <tr key={`playerNameRow${i}_${this.state.playerNames[i]}`}>
          <td key={`playerName_${i}`}>{this.state.playerNames[i]}</td>
          {this.playerWeeksRow(this.state.playerNames[i])}
        </tr>
      )
    }
    return rows
  }

  handleChange = event => {
    var playerRowName = event.target.id.split('-')[0]
    var weekIndex = event.target.id.split('-')[1]

    var matchups = this.state.matchups
    
    matchups[weekIndex][playerRowName] = event.target.value
    matchups[weekIndex][event.target.value] = playerRowName
    this.setState({
      matchups: matchups
    });
  }

  validateMatchup(weekIndex, playerName) {
    var opponent = this.state.matchups[weekIndex][playerName]
    if (opponent === "") return null;
    
    if (this.state.matchups[weekIndex][opponent] === playerName) {
      return "success";
    }
    else {
      return "error";
    }
  }

  playerWeeksRow(playerName){
    var cols = [];
    for(var i = 0; i< this.state.matchupOptions.length; i++) {
      cols.push(
        <td key={`${playerName}_week${i}`} className='player-week-matchup'>
          <FormGroup controlId={`${playerName}-${i}`} 
            validationState={this.validateMatchup(i, playerName)}>
            <FormControl
              className='player-week-matchup'
              key={`${playerName}${i}`} componentClass="select" 
              onChange={this.handleChange}
              value={this.state.matchups[i][playerName]}> 
                {this.playerWeeksRowOptions(i, playerName)}
            </FormControl>
            <FormControl.Feedback />
          </FormGroup>
        </td>
      )
    }
    return cols;
  }

  playerWeeksRowOptions(weekIndex, rowPlayerName){
    var optionNames = this.state.matchupOptions[weekIndex]
    var options = [<option key={`${rowPlayerName}_week${weekIndex}_blank`} value=""></option>];
    for(var i = 0; i< optionNames.length; i++) {
      if(optionNames[i] !== rowPlayerName){
        options.push(
          <option key={`${rowPlayerName}_week${weekIndex}_${optionNames[i]}`} value={optionNames[i]}>
            {optionNames[i]}
          </option>
        )
      }
    }
    return options;
  }

  validateForm() {
    for(var i = 0; i < this.state.matchups.length; i++) {
      var matchupsOnWeek = this.state.matchups[i];
      var players = Object.keys(matchupsOnWeek)
      var opponents = Object.values(matchupsOnWeek);
      if (opponents.includes("")) {
        return false;  // Check all values filled out
      }
      if (!this.arraysEqual(players.sort(), opponents.sort())) {
        // Check that none are repeated for a given week
        return false;
      }
    }
    return true;
  }

  arraysEqual(_arr1, _arr2) {
    if (!Array.isArray(_arr1) || ! Array.isArray(_arr2) || _arr1.length !== _arr2.length)
      return false;
    var arr1 = _arr1.concat().sort();
    var arr2 = _arr2.concat().sort();

    for (var i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i])
        return false;
    }
    return true;
  }

  async putSeason(leagueId, seasonId, matchups) {
    const {account: {accountId}} = this.props
    return API.put("league", `/${leagueId}/season/${seasonId}`, {
      body: {
        matchups: matchups
      },
      headers: {
        'x-account-id': accountId
      }
    });
  }

  async getLeague(league) {
    const {account: {accountId}} = this.props
    return API.get("league", `/${league}`, {
      headers: {
        'x-account-id': accountId
      }
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });

    try {
      const {leagueId, seasonId, matchups} = this.state
      await this.putSeason(leagueId, seasonId, matchups)
      const updatedLeague = await this.getLeague(leagueId)
      const storedLeagues = ls.get('leagues')
      ls.set('leagues', {...storedLeagues, [leagueId]: updatedLeague})
      this.props.history.push(`/leagues/${leagueId}/${seasonId}`);
    } catch (e) {
      console.log(e.message);
      this.setState({ isLoading: false });
    }
  }

  render(){
    return (
      <div>
        <PageHeader>Weekly Matchups</PageHeader>
        <form onSubmit={this.handleSubmit}>
          <Table responsive>
            <thead>
              <tr>
               {this.tableHeaders()}
              </tr>
            </thead>
            <tbody>
              {this.tableRows()}
            </tbody>
          </Table>
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating…"
          />
        </form>
      </div>
    );
  }
}

export default withAuth(MatchupTable, ['account'])
