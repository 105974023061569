import React, { Component } from "react";
import Plot from 'react-plotly.js';

export default class MovingAverageScorePlot extends Component {
  // Get average of the last 4 weeks, or as many as possible
  getWeekIndices(array) {
    var maxIndex = array.length-1;
    for (var i = array.length-1; i >= 0; i--) {
      if (array[i] !== null && array[i] !== "") {
        maxIndex = i; 
        break;
      }
    }
    
    var minIndex;
    if (maxIndex >= 4) {
      minIndex = maxIndex-3;
    } else {
      minIndex = 0;
    }

    var indices = [];
    for (var j = minIndex; j <= maxIndex; j++) {
      indices.push(j);
    }
    return indices;
  }

  calculateAverageInArray(array, indices) {
    var sum = 0;
    for(var i = 0; i < indices.length; i++ ){
      var index = indices[i];
      sum += parseFloat(array[index]);
    }
    var avg = sum/indices.length;
    return avg;
  }

  getTraceFromPlayerScores(playerNames, playerScores) {
    if (playerNames === undefined || playerNames.length === 0) {
      return;
    }
    var indices = this.getWeekIndices(playerScores[playerNames[0]]);
    var yValues = [];
    for (var i = 0; i < playerNames.length; i++) {
      var name = playerNames[i];
      var avg = this.calculateAverageInArray(playerScores[name], indices)
      yValues.push(avg);
    }

    var trace = {
      x: playerNames,
      y: yValues,
      type: 'bar'
    }
    return [trace];
  }

  sortNumber(a, b) {
    return b - a;
  }
  

  getLayout(playerNames, playerScores) {
    if (playerNames === undefined || playerNames.length === 0) {
      return;
    }
    var indices = this.getWeekIndices(playerScores[playerNames[0]]);
    var layout = {
      title: String(indices.length) + ' Week Average Scores', 
      xaxis: playerNames, 
      yaxis:{title:'Score'},
      autosize: true,
    }
    return layout
  }

  getStyle() {
    return {
      width: '100%'
    }
  }

  render() {
    return (<Plot 
      data={this.getTraceFromPlayerScores(this.props.playerNames, this.props.playerScores)}
      layout={this.getLayout(this.props.playerNames, this.props.playerScores)}
      useResizeHandler={true} style={this.getStyle()}/>)
  }
}