import React from 'react'

const AuthContext = React.createContext({
    account: null,
    user: null,
    session: null,
    authLoading: true,
    isAuthorized: () => {},
    isAuthenticated: () => {},
    handleLogout: () => {},
    handleLogin: () => {},
    refreshUser: () => {}
})

export default AuthContext