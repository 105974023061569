import React, { Component, Fragment } from "react";
import { PageHeader } from "react-bootstrap";
import ls from "local-storage";
import { API } from "aws-amplify";
import { get } from 'lodash'
import withAuth from '../contexts/withAuth'
import SeasonScoresTable from "../components/SeasonScoresTable"
import SeasonScoresTableDumb from "../components/SeasonScoresTableDumb"
import SummaryTable from "../components/SummaryTable"

import AverageOutcomePlot from "../components/graphs/AverageOutcomePlot";
import MovingAverageScorePlot from "../components/graphs/MovingAverageScorePlot"
import PointsForAgainstPlot from "../components/graphs/PointsForAgainstPlot"
import PointsVsAverageScatterPlot from "../components/graphs/PointsVsAverageScatterPlot"
import PowerRankingsPlot from "../components/graphs/PowerRankingsPlot"
import RankingsPlot from "../components/graphs/RankingsPlot"
import ScoresPlot from "../components/graphs/ScoresPlot"

import NotFound from "../containers/NotFound"

class Season extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leagueId: props.match.params.leagueId,
      seasonId: props.match.params.seasonId,
      weeksCount: 8,
      playerScores: {},
      graphedPlayerScores: {},
      standings: {},
      metaScores: {},
      playerNames: [],
      orderedPlayerNames: [],
      hasError: false,
      isLoading: true
    };
  }

  async componentDidMount() {
    try {
      var localSeasonKey = this.props.match.params.leagueId+this.props.match.params.seasonId
      var storedSeason = ls.get(localSeasonKey) || {}
      var season;
      if (Object.entries(storedSeason).length === 0 && storedSeason.constructor === Object) {
        season = await this.getSeason();
      }
      else{
        season = ls.get(localSeasonKey)
      }

      var localScoreKey = this.props.match.params.leagueId+this.props.match.params.seasonId+'score'
      var storedScores = ls.get(localScoreKey) || {}
      var scores;
      if (Object.entries(storedScores).length === 0 && storedScores.constructor === Object) {
        scores = await this.getScores();
      }
      else{
        scores = storedScores
      }

      if (!scores) {
        scores = this.initializeEmptyScores(season)
      }

      this.setState({ 
        playerScores: this.initializePlayerScoresState(scores.playerScores),
        graphedPlayerScores: this.initializePlayerScoresState(scores.playerScores),
        standings: this.initializeStateFromArray(scores.standings),
        metaScores: this.initializeStateFromArray(scores.metaScores),
        completedWeeksCount: this.inferCompletedWeeksCount(scores.playerScores),
        weeksCount: season.weeksCount,
        playerNames: this.initializePlayerNames(scores.playerScores),
        orderedPlayerNames: this.initializeOrderedPlayerNames(scores.playerScores, scores.standings)
      });
      ls.set(localSeasonKey, season);
      ls.set(localScoreKey, scores);
    } catch (e) {
      console.error(e)
      this.setState({hasError: true})
    }

    this.setState({isLoading: false})
  }

  updateStateWithScoreResponse(scores) {
    this.setState({
      playerScores: this.initializePlayerScoresState(scores.playerScores),
      graphedPlayerScores: this.initializePlayerScoresState(scores.playerScores),
      standings: this.initializeStateFromArray(scores.standings),
      metaScores: this.initializeStateFromArray(scores.metaScores),
      completedWeeksCount: this.inferCompletedWeeksCount(scores.playerScores)
    });
  }

  initializePlayerNames(playerScores) {
    var playerNames = [];
    for (var i = 0; i < playerScores.length; i++ ) {
      playerNames.push(playerScores[i].playerName)
    }
    return playerNames;
  }

  initializeOrderedPlayerNames(playerScores, standings) {
    // Initialize PlayerNames in the order of the most recent ranks
    var completedWeeksCount = this.inferCompletedWeeksCount(playerScores)
    var playerNames = new Array(standings.length);
    for (var i = 0; i < standings.length; i++ ) {
      var name = standings[i].playerName
      var rank = standings[i].rankHistory[completedWeeksCount-1]
      playerNames[rank-1] = name
    }

    return playerNames;
  }

  inferCompletedWeeksCount(playerScores) {
    for (var i = 0; i < playerScores[0].scores.length; i++) {
      if (playerScores[0].scores[i] === null) {
        return i;
      }
    }
    return playerScores[0].scores.length;
  }

  initializePlayerScoresState(apiResponse) {
    var playerScore;
    var playerScoreState = {};
    for (var i = 0; i < apiResponse.length ; i++) {
      playerScore = apiResponse[i];
      // Force a copy to ensure pass by value and not reference
      playerScoreState[playerScore.playerName] = playerScore.scores.slice();
    }
    return playerScoreState;
  }

  initializeEmptyScores({playerNames}) {
    const {seasonId, leagueId} = this.props.match.params
    return {
      seasonId,
      leagueId,
      metaScores: this.initializeEmptyMetaScores(playerNames),
      playerScores: this.initializeEmptyPlayerScores(playerNames),
      standings: this.initializeEmptyStandings(playerNames)
    }
  }

  initializeEmptyMetaScores(playerNames) {
    return playerNames.reduce((acc, playerName) => {
      const metaScore = {playerName, pointsAgainst: 0, pointsAgainstVsAverage: [], pointsFor: 0, pointsForVsAverage: []}
      return [...acc, metaScore]
    }, [])
  }

  initializeEmptyPlayerScores(playerNames) {
    return playerNames.reduce((acc, playerName) => {
      const playerScore = {playerName, scores: Array(this.state.weeksCount).fill(null)}
      return [...acc, playerScore]
    }, [])
  }

  initializeEmptyStandings(playerNames) {
    return playerNames.reduce((acc, playerName) => {
      const standing = {playerName, losses: 0, rankHistory: [], ties: 0, wins: 0}
      return [...acc, standing]
    }, [])
  }

  initializeStateFromArray(apiResponse) {
    var playerStanding;
    var standings = {};
    for (var i = 0; i < apiResponse.length ; i++) {
      playerStanding = apiResponse[i];
      standings[playerStanding.playerName] = playerStanding;
    }
    return standings;
  }

  handleScoreChange = (playerName, weekIndex) => event => {
    var playerScores = this.state.playerScores
    
    if (event.target.value === ""){
      playerScores[playerName][weekIndex] = null;
    }
    else {
      playerScores[playerName][weekIndex] = event.target.value;
    }

    this.setState({
      playerScores: playerScores
    });
  }

  handleScoreSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });

    var floatPlayerScores = {}
    for (var i = 0; i < this.state.playerNames.length; i++){
      var name = this.state.playerNames[i]
      floatPlayerScores[name] = [];
      var scores = this.state.playerScores[name];
      for(var j = 0; j < scores.length; j++) {
        try {
          floatPlayerScores[name].push(parseFloat(scores[j]));
        } catch (e) {
          floatPlayerScores[name].push(null);
        }
      }
    }

    try {
      const scores = await this.putScores(this.state.leagueId, this.state.seasonId, floatPlayerScores)
      this.updateStateWithScoreResponse(scores)
      var localScoreKey = this.props.match.params.leagueId+this.props.match.params.seasonId+'score'
      ls.set(localScoreKey, scores)
      this.setState({
        playerScores: this.initializePlayerScoresState(scores.playerScores),
        graphedPlayerScores: this.initializePlayerScoresState(scores.playerScores),
        standings: this.initializeStateFromArray(scores.standings),
        metaScores: this.initializeStateFromArray(scores.metaScores),
        completedWeeksCount: this.inferCompletedWeeksCount(scores.playerScores),
        playerNames: this.initializePlayerNames(scores.playerScores),
        orderedPlayerNames: this.initializeOrderedPlayerNames(scores.playerScores, scores.standings),
        isLoading: false
      });
    } catch (e) {
      console.log(e.message);
      this.setState({ isLoading: false });
    }
  }

  getSeason() {
    const accountId = get(this.props.account, 'accountId', null)
    return API.get("league", `/${this.state.leagueId}/season/${this.state.seasonId}`, {
      headers: {
        ...accountId && {'x-account-id': accountId}
      }
    });
  }

  async getScores() {
    const accountId = get(this.props.account, 'accountId', null)
    return API.get("league", `/${this.state.leagueId}/season/${this.state.seasonId}/scores`, {
      headers: {
        ...accountId && {'x-account-id': accountId}
      }
    });
  }

  putScores = async (leagueId, seasonId, playerScores) => {
    const {account: {accountId}} = this.props
    return API.put("league", `/${leagueId}/season/${seasonId}/scores`, {
      body:{
        scores: playerScores
      },
      headers: {
        'x-account-id': accountId
      }
    });
  }

  renderScoresTable() {
    const {leagueId} = this.props.match.params
    const {isManager} = this.props
    if (isManager(leagueId)) {  // Check here for if the user is manager
      return <SeasonScoresTable playerScores={this.state.playerScores} playerNames={this.state.playerNames}
          weeksCount={this.state.weeksCount}
          handleChange={this.handleScoreChange} handleScoreSubmit={this.handleScoreSubmit}
          isLoading={this.state.isLoading}/>
    }
    else{
      return <SeasonScoresTableDumb playerScores={this.state.playerScores}
                playerNames={this.state.playerNames} weeksCount={this.state.weeksCount}
              />
    }
  }

  renderScoresGraphs() {
    return (
      <div className="seasonInfo">
        <PageHeader>Season Details</PageHeader>
        {this.renderScoresTable()}
        {!!this.state.completedWeeksCount &&
          <Fragment>
            <ScoresPlot playerScores={this.state.graphedPlayerScores} playerNames={this.state.playerNames}
                        completedWeeksCount={this.state.completedWeeksCount}/>
            <RankingsPlot standings={this.state.standings} playerNames={this.state.playerNames}
                          completedWeeksCount={this.state.completedWeeksCount} />
            <PowerRankingsPlot standings={this.state.standings} playerNames={this.state.orderedPlayerNames} />
            <MovingAverageScorePlot playerScores={this.state.graphedPlayerScores} playerNames={this.state.orderedPlayerNames} />
            <PointsForAgainstPlot metaScores={this.state.metaScores} playerNames={this.state.orderedPlayerNames}
                                  completedWeeksCount={this.state.completedWeeksCount} />
            <AverageOutcomePlot metaScores={this.state.metaScores} playerNames={this.state.orderedPlayerNames}
                                completedWeeksCount={this.state.completedWeeksCount}/>
            <PointsVsAverageScatterPlot metaScores={this.state.metaScores} playerNames={this.state.playerNames}/>
            <SummaryTable standings={this.state.standings} metaScores={this.state.metaScores}
                          playerNames={this.state.orderedPlayerNames}/>
          </Fragment>
        }
      </div>
    );
  }

  render() {
    const {isLoading} = this.state
    if (isLoading) return <div/>
    return <div className="Season">
        {this.state.hasError ? <NotFound/> : this.renderScoresGraphs()}
    </div>;
  }
}

export default withAuth(Season, ['account', 'isManager'])