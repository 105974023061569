import React from 'react'
import {pick} from 'lodash'
import AuthContext from './AuthContext'

const withAuth = (Child, keys) => props => {
  return (
    <AuthContext.Consumer>
      {({...authProps}) => <Child {...props} {...limitAuthProps(authProps, keys)}/>}
    </AuthContext.Consumer>
  )
}

const limitAuthProps = (authProps, keys) => {
  return keys ? pick(authProps, keys) : authProps
}

export default withAuth