import React, { Component } from "react";
import { PageHeader } from "react-bootstrap";

import SeasonScoresTableDumb from "../components/SeasonScoresTableDumb"
import SummaryTable from "../components/SummaryTable"

import AverageOutcomePlot from "../components/graphs/AverageOutcomePlot";
import MovingAverageScorePlot from "../components/graphs/MovingAverageScorePlot"
import PointsForAgainstPlot from "../components/graphs/PointsForAgainstPlot"
import PointsVsAverageScatterPlot from "../components/graphs/PointsVsAverageScatterPlot"
import PowerRankingsPlot from "../components/graphs/PowerRankingsPlot"
import RankingsPlot from "../components/graphs/RankingsPlot"
import ScoresPlot from "../components/graphs/ScoresPlot"

export default class About extends Component {

  aboutText() {
    return "HistoLeague is a fantasy football data visualization app designed to give you a new perspective into the scoring data of YOUR fantasy football league. " +
    "While you might get your NFL insights from sources like ESPN, the Fantasy Footballers, or the Sleeper App, HistoLeague is " +
    "designed to give you an unique look at the teams within your fantasy league - from scoring history, power rankings, and other " +
    "performance trends and indicators.";
  }

  contactMeText() {
    return "HistoLeague started off as a passion project and is being maintained by two fantasy football enthusiasts. Please bear with us through " +
    "the inaugural season for HistoLeague, and we welcome any feedback and suggestions at histoleague@gmail.com."
  }

  graphIntroText() {
    return "Here are the graphs & tables you would currently see while using HistoLeague, displaying some sample data:";
  }

  howItWorksText() {
    return "After creating an account with HistoLeague - create a League & Season that mimicks your fantasy league by inputting the " +
    "player/team names and weekly matchups. Whenever you are ready, come back to HistoLeague and update your " +
    "season with the scores from each fantasy week and HistoLeague will show you graphs unique to your league! " + 
    "Share the graphs with your friends and league members by sharing the URL (if your league is configured as a public league) " +
    "or by adding their HistoLeague accounts as members of your league."
  }

  exampleMetaScores() {
    return {
      "Cpt. America": {
        "pointsFor":1759.3, "pointsAgainst":1637, "consistency": 0.17663,
        "pointsForVsAverage": [21.1, 44.3, 16.8, 3.7, 30.4, 48.4, 9.1, 11.6, -2.2, 7.8, -7.9, -34.9, -23],
        "pointsAgainstVsAverage": [61.3, -3, -37.9, -7.5, 2.1, 2.4, 12.1, 12.4, -20.9, -14.6, 23.5, -31, 4]
      },
      "Black Widow": {
        "pointsFor":1809.9, "pointsAgainst":1511.3, "consistency": 0.16424,
        "pointsForVsAverage": [61.3, -14.8, 12.4, 2.1, -19.6, 2.4, -3, 31.2, 43.3, 22.9, 23.5, 0.2, 13.9],
        "pointsAgainstVsAverage": [21.1, -23, -26, 1.7, -20, 48.4, -23.4, 14.1, -27.3, -49.1, -7.9, -18.5, -12.9]},
      "Hulk": {
        "pointsFor":1550.6, "pointsAgainst":1685.6, "consistency": 0.23181,
        "pointsForVsAverage": [16.6, -3, -5.2, 62.9, -20, -8.6, 12.1, -32.5, -11.6, -49.1, 1.9, -31, -16],
        "pointsAgainstVsAverage": [-22.5, 44.3, -13.4, 10, -19.6, 27.8, 9.1, 25.2, -3.7, 22.9, 22.4, -34.9, -16.1]},
      "Iron Man": {
        "pointsFor":1740.69, "pointsAgainst":1560.1, "consistency": 0.19144,
        "pointsForVsAverage": [-22.5, 12.8, -37.9, 1.7, 4.4, 27.8, 19.3, 12.4, -27.3, 50.4, 22.4, 39.1, 4],
        "pointsAgainstVsAverage": [ 16.6, -42.4, 16.8, 2.1, -33.2, -8.6, -13.2, 11.6, 43.3, -48.6, 1.9, 2.7, -23]},
      "Hawkeye": {
        "pointsFor":1380.89, "pointsAgainst":1823.6, "consistency": 0.17036,
        "pointsForVsAverage": [-22.3, -42.4, -26, -7.5, -33.2, -6.3, -13.2, 14.1, -20.9, -48.6, -36.7, 2.7, -12.9],
        "pointsAgainstVsAverage": [ -0.5, 12.8, 12.4, 3.7, 4.4, -3, 19.3, 31.2, -2.2, 50.4, 8, 39.1, 13.9]},
      "Thor": {
        "pointsFor":1563.19, "pointsAgainst":1587, "consistency": 0.12028,
        "pointsForVsAverage": [-0.5, -23, -13.4, 10, 2.1, -3, -23.4, 25.2, -3.7, -14.6, 8, -18.5, -16.1],
        "pointsAgainstVsAverage": [-22.3, -14.8, -5.2, 62.9, 30.4, -6.3, -3, -32.5, -11.6, 7.8, -36.7, 0.2, -16]}
    }
  }

  exampleStandings() {
    return {
      "Cpt. America": {
        "rankHistory": [4, 2, 2, 2, 2, 1, 2, 2, 2, 2, 2, 3, 3],
        "pointsForVsAverage": [21.1, 44.3, 16.8, 3.7, 30.4, 48.4, 9.1, 11.6, -2.2, 7.8, -7.9, -34.9, -23.0],
        "pointsAgainstVsAverage": [61.3, -3.0, -37.9, -7.5, 2.1, 2.4, 12.1, 12.4, -20.9, -14.6, 23.5, -31.0, 4.0],
        "powerRank": 3, "wins": 7, "ties": 0, "losses": 6
      },
      "Black Widow": {
        "rankHistory": [1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1],
        "pointsForVsAverage": [61.3, -14.8, 12.4, 2.1, -19.6, 2.4, -3.0, 31.2, 43.3, 22.9, 23.5, 0.2, 13.9],
        "pointsAgainstVsAverage": [21.1, -23.0, -26.0, 1.7, -20.0, 48.4, -23.4, 14.1, -27.3, -49.1, -7.9, -18.5, -12.9],
        "powerRank": 1.25, "wins": 12, "ties": 0, "losses": 1
      },
      "Hulk": {
        "rankHistory": [2, 3, 3, 3, 3, 3, 3, 4, 4, 4, 5, 5, 4],
        "pointsForVsAverage": [16.6, -3.0, -5.2, 62.9, -20.0, -8.6, 12.1, -32.5, -11.6, -49.1, 1.9, -31.0, -16.0],
        "pointsAgainstVsAverage": [-22.5, 44.3, -13.4, 10.0, -19.6, 27.8, 9.1, 25.2, -3.7, 22.9, 22.4, -34.9, -16.1],
        "powerRank": 5, "wins": 6, "ties": 0, "losses": 7
      },
      "Iron Man": {
        "rankHistory": [6, 4, 5, 5, 4, 4, 4, 3, 3, 3, 3, 2, 2],
        "pointsForVsAverage": [-22.5, 12.8, -37.9, 1.7, 4.4, 27.8, 19.3, 12.4, -27.3, 50.4, 22.4, 39.1, 4.0],
        "pointsAgainstVsAverage": [16.6, -42.4, 16.8, 2.1, -33.2, -8.6, -13.2, 11.6, 43.3, -48.6, 1.9, 2.7, -23.0],
        "powerRank": 3, "wins": 9, "ties": 0, "losses": 4
      },
      "Hawkeye": {
        "rankHistory": [5, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
        "pointsForVsAverage": [-22.3, -42.4, -26.0, -7.5, -33.2, -6.3, -13.2, 14.1, -20.9, -48.6, -36.7, 2.7, -12.9],
        "pointsAgainstVsAverage": [-0.5, 12.8, 12.4, 3.7, 4.4, -3.0, 19.3, 31.2, -2.2, 50.4, 8.0, 39.1, 13.9],
        "powerRank": 5.25, "wins": 0, "ties": 0, "losses": 13
      },
      "Thor": {
        "rankHistory": [3, 5, 4, 4, 5, 5, 5, 5, 5, 5, 4, 4, 5],
        "pointsForVsAverage": [-0.5, -23.0, -13.4, 10.0, 2.1, -3.0, -23.4, 25.2, -3.7, -14.6, 8.0, -18.5, -16.1],
        "pointsAgainstVsAverage": [-22.3, -14.8, -5.2, 62.9, 30.4, -6.3, -3.0, -32.5, -11.6, 7.8, -36.7, 0.2, -16.0],
        "powerRank": 3.5, "wins": 5, "ties": 0, "losses": 8
      }
    }
  }
  examplePlayerScores() {
    return {
      "Black Widow": [187.0, 110.9, 138.1, 127.8, 106.1, 128.1, 122.7, 156.9, 169.0, 148.6, 149.2, 125.9, 139.6],
      "Iron Man" : [103.2, 138.5, 87.8, 127.4, 130.1, 153.5, 145.0, 138.1, 98.4, 176.1, 148.1, 164.8, 129.7],
      "Cpt. America": [146.8, 170.0, 142.5, 129.4, 156.1, 174.1, 134.8, 137.3, 123.5, 133.5, 117.8, 90.8, 102.7],
      "Hulk": [142.3, 122.7, 120.5, 188.6, 105.7, 117.1, 137.8, 93.2, 114.1, 76.6, 127.6, 94.7, 109.7],
      "Thor": [125.2, 102.7, 112.3, 135.7, 127.8, 122.7, 102.3, 150.9, 122.0, 111.1, 133.7, 107.2, 109.6],
      "Hawkeye": [103.4, 83.3, 99.7, 118.2, 92.5, 119.4, 112.5, 139.8, 104.8, 77.1, 89.0, 128.4, 112.8]
    }
  }
  examplePlayerNames() {
    return ["Cpt. America", "Black Widow", "Hulk", "Iron Man", "Hawkeye", "Thor"];
  }

  exampleOrderedPlayerNames() {
    return ["Black Widow", "Iron Man", "Cpt. America", "Hulk", "Thor", "Hawkeye"];
  }

  sampleScoresTable(){
    return(<SeasonScoresTableDumb playerScores={this.examplePlayerScores()} playerNames={this.examplePlayerNames()} weeksCount={13} />)
  }

  sampleScoresPlot() {
    return (<ScoresPlot playerScores={this.examplePlayerScores()} playerNames={this.examplePlayerNames()} completedWeeksCount={13} />)
  }

  sampleRankingsPlot() {
    return (<RankingsPlot standings={this.exampleStandings()} playerNames={this.examplePlayerNames()} completedWeeksCount={13} />)
  }

  samplePowerRankingsPlot() {
    return(<PowerRankingsPlot standings={this.exampleStandings()} playerNames={this.exampleOrderedPlayerNames()} completedWeeksCount={13} />)
  }

  sampleMovingAverageScorePlot(){
    return (<MovingAverageScorePlot playerScores={this.examplePlayerScores()} playerNames={this.exampleOrderedPlayerNames()} completedWeeksCount={13} />)
  }

  samplePointsForAgainstPlot() {
    return (<PointsForAgainstPlot metaScores={this.exampleMetaScores()} playerNames={this.exampleOrderedPlayerNames()} completedWeeksCount={13} />)
  }

  sampleAverageOutcome() {
    return (<AverageOutcomePlot metaScores={this.exampleMetaScores()} playerNames={this.exampleOrderedPlayerNames()} completedWeeksCount={13} />)
  }

  sampleScatterVsLeagueAvgPlot() {
    return(<PointsVsAverageScatterPlot metaScores={this.exampleMetaScores()} playerNames={this.examplePlayerNames()}/>)
  }

  sampleSummaryTable() {
    return(<SummaryTable standings={this.exampleStandings()} metaScores={this.exampleMetaScores()} playerNames={this.exampleOrderedPlayerNames()}/>)
  }

  render() {
    return (
      <div className="About">
        <PageHeader>About</PageHeader>
        <div className="intro"> 
          {this.aboutText()}
          <br/><br/>
          {this.contactMeText()}
        </div>
        <PageHeader>How it Works</PageHeader>
        <div className="howitworks"> {this.howItWorksText()} </div>
        <PageHeader>Examples</PageHeader>
        <div className="examples"> {this.graphIntroText()} </div>
        {this.sampleScoresTable()}
        {this.sampleScoresPlot()}
        {this.sampleRankingsPlot()}
        {this.samplePowerRankingsPlot()}
        {this.sampleMovingAverageScorePlot()}
        {this.samplePointsForAgainstPlot()}
        {this.sampleAverageOutcome()}
        {this.sampleScatterVsLeagueAvgPlot()}
        {this.sampleSummaryTable()}
      </div>
    );
  }
}