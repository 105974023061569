export default {
    s3: {
      REGION: "us-east-1",
      BUCKET: "colmanjin.com"
    },
    apiGateway: {
      REGION: "us-east-1",
      ACCOUNT_URL: "https://av5fbdmyxl.execute-api.us-east-1.amazonaws.com/prod/account",
      // ACCOUNT_URL: "https://7yjqk0l0o3.execute-api.us-east-1.amazonaws.com/test/account",
      LEAGUE_URL: "https://av5fbdmyxl.execute-api.us-east-1.amazonaws.com/prod/league",
      // LEAGUE_URL: "https://7yjqk0l0o3.execute-api.us-east-1.amazonaws.com/test/league"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_NyZguJy5e",
      APP_CLIENT_ID: "3dj4o1luoughae38e8hub1ehqr",
      IDENTITY_POOL_ID: "us-east-1:c761555b-45e5-4bb8-bf01-c7e454cbbe83"
    }
  };