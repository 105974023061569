import React, {useContext, Fragment} from 'react'
import {Button, ControlLabel, FormControl, FormGroup, ListGroupItem} from "react-bootstrap";
import AuthContext from '../contexts/AuthContext'
import LoaderButton from "../components/LoaderButton";
import "./LeagueMembers.css";

function LeagueMembers({leagueId, members, newMember, isNew = false, handleChange, handleMemberAdd, handleMembersSave, handleMemberRemove, isSaving}) {
  const {isManager} = useContext(AuthContext)

  const renderMembers = () => {
    return (
      <div>
        {(isNew || isManager(leagueId)) &&
        <div className='form-member-container'>
          <ControlLabel>Members</ControlLabel>
          <FormGroup id='form-group-members-add' controlId="newMember" className="form-group-members-add">
            <FormControl
              type="email"
              value={newMember}
              onChange={handleChange}
              placeholder="Enter emails of members..."
            />
            <Button bsStyle="success" className="listSideButton" onClick={handleMemberAdd}>Add</Button>
          </FormGroup>
          <Fragment>
            {members.map(member => {
              return (
                <div className="members-remove" key={member}>
                  <ListGroupItem header={member} className="list-group-item-members-remove"/>
                  <Button bsStyle="danger" className="listSideButton" onClick={handleMemberRemove(member)}>Remove</Button>
                </div>
              )
            })}
            {!isNew &&
              <LoaderButton block bsStyle="primary" bsSize="large" type="submit"
                className="saveList"
                onClick={handleMembersSave}
                isLoading={isSaving}
                text="Save Members List"
                loadingText="Saving…"
              />
            }
          </Fragment>
        </div>
        }
      </div>
    )
  }

  return (
    <div>
      {renderMembers()}
    </div>
  )
}

export default LeagueMembers