import { API } from "aws-amplify";
import ls from "local-storage"
import React, { Component } from "react";
import { Button, FormGroup, FormControl, ControlLabel, PageHeader } from "react-bootstrap";
import withAuth from '../contexts/withAuth'
import LoaderButton from "../components/LoaderButton";
import MatchupTable from "../components/MatchupTable";

class NewSeason extends Component {
  constructor(props) {
    super(props);

    this.state = {
      settingsConfigured: false,
      isLoading: false,
      seasonId: "",
      playerCount: 8,
      weeksCount: 13,
      playerNames: ["", "", "", "", "", "", "", ""],
      existingSeasons: []
    };
  }
  async componentDidMount() {
    const {leagueId} = this.props.match.params
    const storedLeagues = ls.get('leagues')
    const storedLeague = storedLeagues[leagueId] ? storedLeagues[leagueId] : await this.getLeague(leagueId)
    ls.set('leagues', {...storedLeagues, [leagueId]: storedLeague})
    this.setState({existingSeasons: storedLeague['seasons']})
  }

  validateForm() {
    var counts = [];
    if (this.state.seasonId === "") {
      return false;
    }
    for (var i = 0; i < this.state.playerNames.length; i++){
      // Check for empty str
      if (this.state.playerNames[i] === "")
        return false;
      // Check for duplicates
      if (counts[this.state.playerNames[i]] === undefined) {
        counts[this.state.playerNames[i]]=1;
      }
      else {
        return false;
      }
    }
    return true;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handlePlayersChange = event => {
    if (event.target.value < this.state.playerCount) {
      this.setState({
          [event.target.id]: parseInt(event.target.value),
          playerNames: this.state.playerNames.slice(0, event.target.value)
      })
    }
    else {
        var arr = [];
        for(var i = 0; i<(event.target.value-this.state.playerCount); i++) {
          arr.push("");
        }
        this.setState({
            [event.target.id]: parseInt(event.target.value),
            playerNames: [...this.state.playerNames, ...arr]
        });
    }
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ settingsConfigured: true });
  }

  updateNamesArray = (event, index) => {
    var playerNames = this.state.playerNames
    playerNames[index] = event.target.value
    this.setState({
      playerNames: playerNames
    })
  }

  async getLeague(league) {
    const {account: {accountId}} = this.props
    return API.get("league", `/${league}`, {
      headers: {
        'x-account-id': accountId
      }
    });
  }

  handleBackClick = () => {
    this.setState({
      settingsConfigured: false
    })
  };

  renderMatchupTable(){
    return(
      <div className="NewSeason">
        <MatchupTable playerCount={this.state.playerCount} playerNames={this.state.playerNames} weeksCount={this.state.weeksCount}
          leagueId={this.props.match.params.leagueId} seasonId={this.state.seasonId} history={this.props.history}/>
        <Button block bsStyle="default" bsSize="large" type="submit" onClick={this.handleBackClick}> Back </Button>
      </div>
    )
  }

  renderRemainingSeasonOptions(){
    var available = ['201920', '201819', '201718', '201617', '201516', '201415', '201314', '201213', '201112', '201011'];
    var toRender = []
    for(var i = 0; i < available.length; i++) {
      if(this.state.existingSeasons.indexOf(available[i]) === -1){
        toRender.push(
          <option value={available[i]} key={available[i]}>{this.formatSeasonIdStr(available[i])}</option>
        )
      }
    }
    return toRender;
  }

  formatSeasonIdStr(seasonId) {
    var prefix = String(seasonId).substring(0,2);
    var firstSuffix = String(seasonId).substring(2,4);
    var secondSuffix = String(seasonId).substring(4,6);
    return prefix + firstSuffix + " - " + prefix + secondSuffix;
  }

  render() {
    return (
      <div className="NewSeason">
        {this.state.settingsConfigured ? this.renderMatchupTable() :
          <div className="NewSeason">
            <PageHeader>New Season Settings</PageHeader>
            <form onSubmit={this.handleSubmit}>
              <FormGroup controlId="seasonId" bsSize="large">
                <ControlLabel>NFL Season</ControlLabel>
                <FormControl componentClass="select" placeholder="select" value={this.state.seasonId} onChange={this.handleChange}> 
                  <option value=""></option>
                  {this.renderRemainingSeasonOptions()}
                </FormControl>
              </FormGroup>
              <FormGroup controlId="playerCount" bsSize="large">
                <ControlLabel>Number of Players</ControlLabel>
                <FormControl componentClass="select" placeholder="select" value={this.state.playerCount} onChange={this.handlePlayersChange}> 
                    <option value="6">6</option>
                    <option value="8">8</option>
                    <option value="10">10</option>
                    <option value="12">12</option>
                    <option value="14">14</option>
                </FormControl>
              </FormGroup>
              <FormGroup controlId="weeksCount" bsSize="large">
                <ControlLabel>Number of Regular Season Weeks</ControlLabel>
                <FormControl componentClass="select" placeholder="select" value={this.state.weeksCount} onChange={this.handleChange}> 
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                </FormControl>
              </FormGroup>
              <FormGroup controlId="playerNames" bsSize="large">
                <ControlLabel>Player or Team Names</ControlLabel>
                {
                  this.state.playerNames.map((name, index) => {
                    return (
                      <FormControl key={`${index}`} type="text" placeholder={`Player/Team ${index+1} Name`} value={`${name}`}
                                  onChange={(e) => this.updateNamesArray(e, index)} />
                    )
                  })
                }
              </FormGroup>
              <LoaderButton
                block
                bsStyle="primary"
                bsSize="large"
                disabled={!this.validateForm()}
                type="submit"
                text="Next"
              />
            </form>
          </div>
        }
      </div>
    );
  }
}

export default withAuth(NewSeason, ['account'])