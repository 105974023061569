import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute"
import UnauthenticatedRoute from "./components/UnauthenticatedRoute"
import About from "./containers/About";
import Home from "./containers/Home";
import Leagues from "./containers/Leagues";
import Login from "./containers/Login";
import NewLeague from "./containers/NewLeague";
import NewSeason from "./containers/NewSeason";
import NotFound from "./containers/NotFound";
import ResetPassword from "./containers/ResetPassword";
import Season from "./containers/Season";
import Signup from "./containers/Signup";

import Settings from "./containers/Settings";
import ChangePassword from "./containers/ChangePassword";

export default ({childProps}) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/about" exact component={About} props={childProps} />
    <AppliedRoute path="/leagues/new" exact component={NewLeague} props={childProps} />
    <AppliedRoute path="/leagues/:leagueId" exact component={Leagues} props={childProps} />
    <AppliedRoute path="/leagues/:leagueId/new" exact component={NewSeason} props={childProps} />
    <AppliedRoute path="/leagues/:leagueId/:seasonId" exact component={Season} props={childProps} />
    <AppliedRoute path="/login" exact component={Login} props={childProps} />
    <AppliedRoute path="/signup" exact component={Signup} props={childProps} />

    <AuthenticatedRoute path="/settings" exact component={Settings} props={childProps} />
    <AuthenticatedRoute path="/settings/password" exact component={ChangePassword} props={childProps} />

    <UnauthenticatedRoute path="/login/reset" exact component={ResetPassword} props={childProps}
    />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;