import React, { Component } from "react";
import Plot from 'react-plotly.js';

export default class PointsForAgainstPlot extends Component {

  getTraceFromMetaScores(playerNames, metaScores, completedWeeksCount) {
    if (playerNames === undefined || playerNames.length === 0) {
      return;
    }
    var playersPointsFor = [];
    var playersPointsAgainst = [];
    for (var i = 0; i < playerNames.length; i++) {
      var name = playerNames[i];
      var pf = (metaScores[name].pointsFor/completedWeeksCount).toFixed(3);
      var pa = (metaScores[name].pointsAgainst/completedWeeksCount).toFixed(3);
      
      playersPointsFor.push(pf);
      playersPointsAgainst.push(pa);
    }

    var pointsFor = {
      x: playerNames,
      y: playersPointsFor,
      name: 'Avg. Points For',
      type: 'bar'
    }
    var pointsAgainst = {
      x: playerNames,
      y: playersPointsAgainst,
      name: 'Avg. Points Against',
      type: 'bar'
    }
    return [pointsFor, pointsAgainst];
  }

  getLayout(playerNames) {
    if (playerNames === undefined || playerNames.length === 0) {
      return;
    }
    var layout = {
      title: 'Average Weekly Points For & Against', 
      xaxis: playerNames, 
      yaxis: {title:'Score'},
      autosize: true,
    }
    return layout
  }

  getStyle() {
    return {
      width: '100%'
    }
  }

  render() {
    return (<Plot 
      data={this.getTraceFromMetaScores(this.props.playerNames, this.props.metaScores, this.props.completedWeeksCount)}
      layout={this.getLayout(this.props.playerNames)}
      useResizeHandler={true} style={this.getStyle()}/>)
  }
}