import React, {Component} from "react";
import { Table, PageHeader } from "react-bootstrap";

export default class SeasonScoresTable extends Component {
  tableHeaders() {
    var headers = [<td key="empty"></td>];
    var header_cols = ["Record", "Points For", "Points Against", "Average PF", "Average PA", "Consistency", "Power Rank"]
    for(var i = 0; i < header_cols.length; i++){
      headers.push(<td className="summary-table-heading" key={`summary_table_${header_cols[i]}`}>{header_cols[i]}</td>)
    }
    return headers
  }
 
  tableRows(){
    var rows = [];
    var playerNames = this.props.playerNames;
    var metaScores = this.props.metaScores;
    var standings = this.props.standings;
    for(var i = 0; i < playerNames.length; i++){
      var playerName = playerNames[i];
      var playerStats = metaScores[playerName];
      var playerStandings = standings[playerName];
      rows.push(
        <tr key={`playerNameRow${i}_${playerNames[i]}`}>
          <td key={`playerName_${i}`}>
            {playerName}
          </td>
          <td key={`playerName_${i}_record`}>
            {playerStandings['wins']}-{playerStandings['losses']}-{playerStandings['ties']}
          </td>
          <td key={`playerName_${i}_pf`}>
            {playerStats['pointsFor'].toFixed(1)}
          </td>
          <td key={`playerName_${i}_pa`}>
            {playerStats['pointsAgainst'].toFixed(1)}
          </td>
          <td key={`playerName_${i}_apf`}>
            {(playerStats['pointsFor']/playerStats["pointsForVsAverage"].length).toFixed(2)}
          </td>
          <td key={`playerName_${i}_apa`}>
            {(playerStats['pointsAgainst']/playerStats["pointsAgainstVsAverage"].length).toFixed(2)}
          </td>
          <td key={`playerName_${i}_consistency`}>
            &plusmn; {(playerStats['consistency']*100).toFixed(3)}
          </td>
          <td key={`playerName_${i}_power_rank`}>
            {parseFloat(playerStandings['powerRank']).toFixed(1)}
          </td>
        </tr>
      )
    }
    return rows
  }

  render(){
    // var partitions = this.scoringColorPartitions()
    return (
      <div>
        <PageHeader>Season Summary</PageHeader>
        <Table responsive>
          <thead>
            <tr>
              {this.tableHeaders()}
            </tr>
          </thead>
          <tbody>
            {this.tableRows()}
          </tbody>
        </Table>
      </div>
    );
  }
}
