import React, { Component } from "react";
import Plot from 'react-plotly.js';

export default class PointsForAgainstPlot extends Component {

  getTraceFromMetaScores(playerNames, metaScores) {
    if (playerNames === undefined || playerNames.length === 0) {
      return;
    }
    var trace;
    var traces = [];
    for (var i = 0; i < playerNames.length; i++) {
      var playerName = playerNames[i];      
      trace = {
        type: 'scatter',
        mode: 'markers',
        x: metaScores[playerName].pointsForVsAverage,
        y: metaScores[playerName].pointsAgainstVsAverage,
        name: playerName,
        hovertemplate: '<b>PF vs. league Avg</b>: %{x}<br>' +
                       '<b>PA vs. league Avg</b>: %{y}',
        marker: { size: 12 }
      }
      traces.push(trace)
    }
    return traces;
  }

  getLayout() {
    var layout = {
      hovermode:'closest',
      title: '"Luckiness" Plot - Match Outcomes Versus League Avg. Score',
      xaxis: {
        title:'Player Score minus Avg. Score',
        showgrid: true
      }, 
      yaxis: {
        title:'Opponent Score minus Avg. Score',
        showgrid: true
      },
      shapes: [
        {
          type: 'line',
          x0: -80,
          y0: -80,
          x1: 80,
          y1: 80,
          line: {
            color: 'rgb(25, 25, 25)',
            width: 3,
            dash: 'dashdot'
          }
        },
        { // SVG Path for Lucky Win for Positive PF
          type: 'path',
          path: 'M 0 0 L 80 80 L 80 0 Z',
          fillcolor: 'rgba(44, 160, 101, 0.2)',
          line: {
            color: 'rgb(44, 160, 101)',
            width: 1
          }
        },
        { // SVG Path for Lucky Win for Negative PF
          type: 'path',
          path: 'M 0 0 L -80 -80 L 0 -80 Z',
          fillcolor: 'rgba(44, 160, 101, 0.2)',
          line: {
            color: 'rgb(44, 160, 101)',
            width: 1
          }
        },
        { // SVG Path for Lucky Loss for Positive PF
          type: 'path',
          path: 'M 0 0 L 80 80 L 0 80 Z',
          fillcolor: 'rgba(255, 105, 97, 0.2)',
          line: {
            color: 'rgb(255, 105, 97)',
            width: 1
          }
        },
        { // SVG Path for Lucky Loss for Negative PF
          type: 'path',
          path: 'M 0 0 L -80 -80 L -80 0 Z',
          fillcolor: 'rgba(255, 105, 97, 0.2)',
          line: {
            color: 'rgb(255, 105, 97)',
            width: 1
          }
        },
      ],
      autosize: true,
    }
    return layout
  }

  getStyle() {
    return {
      width: '100%'
    }
  }

  render() {
    return (
      <span>
      <Plot 
        data={this.getTraceFromMetaScores(this.props.playerNames, this.props.metaScores)}
        layout={this.getLayout()}
        useResizeHandler={true} style={this.getStyle()}/>
      <p><b>How to interpret this graph:</b><br/>All points above the dashed diagonal black line are losses, and all points below it are wins. 
      Points in the green highlighted areas are considered "lucky" wins, and points in the red highlighted areas are considered "unlucky" losses.</p>
      </span>
    )
  }
}