import React, { Component } from "react";
import ls from "local-storage";
import {PageHeader, ListGroup, ListGroupItem} from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap";
import { API } from "aws-amplify";
import LeagueMembers from './LeagueMembers'
import NotFound from "../containers/NotFound"
import withAuth from '../contexts/withAuth'
import localStorageService from '../services/localStorageService'
import "./LeagueMembers.css";
import {get} from "lodash";

class Leagues extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leagueId: "",
      leagueName: "",
      seasons: [],
      hasError: false,
      members: [],
      newMember: "",
      isPrivate: "",
      isSaving: false
    };
  }

  async componentDidMount() {
    try {
      const {leagueId} = this.props.match.params
      const storedLeague = ls.get('leagues')[leagueId] || null
      const league = storedLeague ? storedLeague  : await this.getLeague(leagueId)

      this.setState({
        leagueId,
        leagueName: league.leagueName,
        seasons: JSON.parse("["+ league.seasons + "]"),
        isPrivate: league.isPrivate,
        members: league.emailAddresses
      });
    } catch (e) {
      this.setState({hasError: true})
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const {leagueId} = this.state
    if (leagueId && prevState.leagueId !== leagueId) {
      const storedLeague = ls.get('leagues')[leagueId] || null
      const league = storedLeague ? storedLeague  : await this.getLeague(leagueId)
      await this.localStoreSeasonsResponse(league.seasons)
    }
  }

  async getLeague(leagueId) {
    const accountId = get(this.props.account, 'accountId', null)
    return API.get("league", `/${leagueId}`, {
      headers: {
        ...accountId && {'x-account-id': accountId}
      }
    });
  }

  getSeason(seasonId) {
    const accountId = get(this.props.account, 'accountId', null)
    return API.get("league", `/${this.state.leagueId}/season/${seasonId}`, {
      headers: {
        ...accountId && {'x-account-id': accountId}
      }
    });
  }

  async localStoreSeasonsResponse(seasons) {
    for(var i = 0; i < seasons.length; i++) {
      var key = this.state.leagueId + seasons[i]
      var storedSeason = ls.get(key)

      if ( storedSeason == null || (Object.entries(storedSeason).length === 0 && storedSeason.constructor === Object)) {
        const season = await this.getSeason(seasons[i]);
        ls.set(key, season);
      }
    }
  }

  renderLeagueInfo(seasons) {
    const {isManager} = this.props
    const {leagueId} = this.state
    return [{}].concat(seasons).map(
      (season_id, i) =>
        i !== 0
          ? <LinkContainer key={season_id} to={`/leagues/${this.props.match.params.leagueId}/${season_id}`} >
              <ListGroupItem header={this.formatSeasonIdStr(season_id)}>
              </ListGroupItem>
            </LinkContainer>
          : isManager(leagueId) ?
              <LinkContainer key="new" to={`/leagues/${this.state.leagueId}/new`}>
                <ListGroupItem>
                  <h4>
                    <b>{"\uFF0B"}</b> Create a new Season
                  </h4>
                </ListGroupItem>
              </LinkContainer> :
          <div key={season_id}/>
    );
  }

  formatSeasonIdStr(seasonId) {
    var prefix = String(seasonId).substring(0,2);
    var firstSuffix = String(seasonId).substring(2,4);
    var secondSuffix = String(seasonId).substring(4,6);

    return prefix + firstSuffix + " - " + prefix + secondSuffix;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  validateNewMember() {
    const {newMember, members} = this.state
    if (members.includes(newMember)) return false;

    if (newMember) return true;
    else return false;
  }

  handleMemberAdd = () => {
    const {members, newMember} = this.state
    if (this.validateNewMember()) {
      this.setState({
        members: [...members, newMember],
        newMember: ""
      })
    }
  }

  handleMemberRemove = memberRemove => () => {
    const {members} = this.state
    const newMembers = members.filter(member => member !== memberRemove)
    this.setState({
      members: newMembers
    })
  }

  handleMembersSave = async e => {
    e.preventDefault()
    this.setState({ isSaving: true })
    const savedLeague = await this.saveMembers()
    localStorageService.updateLeagueStorage(savedLeague)
    this.setState({ isSaving: false })
  }

  saveMembers = async () => {
    const {account: {accountId}} = this.props
    const {members, leagueId, isPrivate, leagueName} = this.state
    return API.put("league", `/${leagueId}`, {
      body:{
        leagueName,
        isPrivate,
        emailAddresses: members
      },
      headers: {
        'x-account-id': accountId
      }
    });
  }

  renderSeasons() {
    return (
      <div className="seasons">
        <PageHeader>{this.state.leagueName} - Seasons</PageHeader>
        <ListGroup>
          {!this.state.isLoading && this.renderLeagueInfo(this.state.seasons)}
        </ListGroup>
      </div>
    );
  }

  render() {
    return <div className="Leagues">
      {this.state.hasError ? <NotFound/> : this.renderSeasons()}
      <LeagueMembers
        leagueId={this.state.leagueId}
        handleMemberAdd={this.handleMemberAdd}
        handleMemberRemove={this.handleMemberRemove}
        handleMembersSave={this.handleMembersSave}
        handleChange={this.handleChange}
        members={this.state.members}
        newMember={this.state.newMember}
        isSaving={this.state.isSaving}
      />
    </div>;
  }
}

export default withAuth(Leagues, ['account', 'isManager'])