import { API } from "aws-amplify"
import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import withAuth from '../contexts/withAuth'
import LeagueMembers from './LeagueMembers'
import localStorageService from '../services/localStorageService'
import "./NewLeague.css";

class NewLeague extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      content: "",
      leagueName: "",
      isPrivate: "false",
      userName: "",
      members: [],
      newMember: ""
    };
  }

  validateForm() {
    return this.state.leagueName.length > 0;
  }

  validateNewMember() {
    const {newMember, members} = this.state
    if (members.includes(newMember)) return false;

    if (newMember) return true;
    else return false;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });

    try {
      const {history, refreshUser} = this.props
      const {leagueName, isPrivate, members} = this.state
      const newLeague = await this.createLeague({
        leagueName,
        isPrivate: isPrivate === "true",
        emailAddresses: members
      });

      localStorageService.updateLeagueStorage(newLeague)
      await refreshUser(true)
      history.push("/");
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false });
    }
  }

  createLeague(leagueInfo) {
    const {user: {username}, account: {accountId}} = this.props
    return API.post("account", `/${username}/leagues`, {
      body: leagueInfo,
      headers: {
        'x-account-id': accountId
      }
    });
  }

  handleMemberAdd = () => {
    const {members, newMember} = this.state
    if (this.validateNewMember()) {
      this.setState({
        members: [...members, newMember],
        newMember: ""
      })
    }
  }

  handleMemberRemove = memberRemove => () => {
    const {members} = this.state
    const newMembers = members.filter(member => member !== memberRemove)
    this.setState({
      members: newMembers
    })
  }

  render() {
    return (
      <div className="NewLeague">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="leagueName" bsSize="large">
            <ControlLabel>League Name</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.leagueName}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="isPrivate" bsSize="large">
            <ControlLabel>League Visibility</ControlLabel>
            <FormControl componentClass="select" placeholder="select" value={this.state.isPrivate} onChange={this.handleChange}> 
                <option value="false">Public</option>
                <option value="true">Private</option>
            </FormControl>
            <p>Private leagues can only be seen by you and other HistoLeague accounts that you allow,
               while Public leagues can be viewed by anyone.</p>
          </FormGroup>
          <LeagueMembers
            isNew
            handleMemberAdd={this.handleMemberAdd}
            handleMemberRemove={this.handleMemberRemove}
            handleChange={this.handleChange}
            members={this.state.members}
            newMember={this.state.newMember}
          />
          <p>Add the emails of other HistoLeague accounts as members of this league, so the have a shortcut to this league page (all leagues),
             and can see this league's information (private leagues).</p>
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating…"
          />
        </form>
      </div>
    );
  }
}

export default withAuth(NewLeague)