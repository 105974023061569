import React, {useState, useEffect, useCallback, useMemo} from 'react'
import { Auth, API } from 'aws-amplify'
import { get } from 'lodash'
import ls from 'local-storage'
import AuthContext from './AuthContext'
import {  withRouter } from "react-router-dom";

function AuthProvider({children, history}) {
  const [user, setUser] = useState(null)
  const [account, setAccount] = useState(null)
  const [session, setSession] = useState(null)
  const [authLoading, setAuthLoading] = useState(true)

  const loadAuth = useCallback(async forceRefresh => {
    if (!!session && session.isValid() && (!user || forceRefresh)) {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser()
        const userAccount = await getAccount(authenticatedUser.username)
        setUser(authenticatedUser)
        setAccount(userAccount)
      } catch (e) {
        console.error('Error during authorization', e)
      } finally {
        setAuthLoading(false)
      }
    }
  }, [session, user])

  useEffect(() => {
    loadAuth()
  }, [session, user, loadAuth])

  const isAuthenticated = useCallback(() => !!session && session.isValid(), [session])

  useEffect(() => {
    const loadSession = async () => {
      if (!isAuthenticated()) {
        try {
          const session = await Auth.currentSession()
          setSession(session)
        } catch (e) {
          console.error('Error during authentication')
          setAuthLoading(false)
        }
      }
    }

    loadSession()
  }, [session, isAuthenticated])

  const isAuthorized = useCallback(() => {
    return !!account
  }, [account])

  const getAccount = async username => {
    return API.get("account", `/${username}`)
  }

  const handleLogout = async () => {
    if (!!session && session.isValid()) {
      await Auth.signOut();
    }
    setUser(null)
    setAccount(null)
    setSession(null)
    ls.clear();
    history.push("/login");
  }

  const handleLogin = async (email, password) => {
    setAuthLoading(true)
    try {
      await Auth.signIn(email, password);
      const session = await Auth.currentSession()
      setSession(session)
      history.push("/");
    } catch (e) {
      console.error('Error logging in')
      throw e;
    } finally {
      setAuthLoading(false)
    }
  }

  const refreshUser = async (forceRefresh) => await loadAuth(forceRefresh)

  const isManager = leagueId => {
    const leagues = get(account, 'leagues', null)

    if (!leagues) return false
    return leagues.includes(parseInt(leagueId))
  }

  const isMember = async leagueId => {
    const memberOf = get(account, 'memberOf', [])
    // check local storage
    return memberOf.includes(leagueId)
  }

  const context = useMemo(() => ({
    user,
    account,
    session,
    authLoading,
    isAuthenticated,
    isAuthorized,
    handleLogout,
    handleLogin,
    refreshUser,
    isManager,
    isMember
  }), [user, account, session, authLoading, isAuthenticated, isAuthorized])

  return (
    <AuthContext.Provider value={context}>
      {children}
    </AuthContext.Provider>
  )
}

export default withRouter(AuthProvider)