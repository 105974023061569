import React, { Component, Fragment } from 'react';
import { Link, withRouter } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import withAuth from './contexts/withAuth'

import Routes from "./Routes";
import './App.css';

class App extends Component {

  render() {
    const {isAuthenticated, handleLogout} = this.props
    return (
      <MuiThemeProvider>
        <div className="App container">
          <Navbar fluid collapseOnSelect>
            <Navbar.Header>
              <Navbar.Brand>
                <Link to="/">HistoLeague</Link>
              </Navbar.Brand>
              <Navbar.Toggle />
            </Navbar.Header>
            <Navbar.Collapse>
              <Nav pullRight>
              {isAuthenticated()
                ? <Fragment>
                    <LinkContainer to="/about">
                      <NavItem>About</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/settings">
                      <NavItem>Settings</NavItem>
                    </LinkContainer>
                    <NavItem onClick={handleLogout}>Logout</NavItem>
                  </Fragment>
                : <Fragment>
                    <LinkContainer to="/about">
                      <NavItem>About</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/signup">
                      <NavItem>Signup</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/login">
                      <NavItem>Login</NavItem>
                    </LinkContainer>
                  </Fragment>
              }
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Routes/>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withRouter(withAuth(App, ['isAuthenticated', 'handleLogout']));
