import ls from "local-storage";

class LocalStorageService {
  updateLeagueStorage(league) {
    const savedLeagues = ls.get('leagues') || {}
    const updatedLeagues = {...savedLeagues, [league.leagueId]: league}
    ls.set('leagues', updatedLeagues)
  }
}

export default new LocalStorageService()