import React, {Component} from "react";
import { Table, FormGroup, FormControl } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";

import "./LoaderButton.css";
import "./SeasonScoresTable.css";

export default class SeasonScoresTable extends Component {

  initializePlayerNames(playerScores) {
    var playerNames = [];
    Object.keys(playerScores).forEach(function (name) {
      playerNames.push(name)
    })
    return playerNames;
  }

  initializeEmptyScores(playerNames, weeksCount) {
    var name;
    var seasonScores = new Array(weeksCount);
    for(var weekIndex = 0; weekIndex < weeksCount; weekIndex++) {
      seasonScores[weekIndex] = {}
      for(var nameIndex = 0; nameIndex < playerNames.length; nameIndex++) {
        name = playerNames[nameIndex];
        seasonScores[weekIndex][name] = "";
      }
    }
    return seasonScores;
  }

  tableHeaders() {
    var headers = [<td key="emptyweek"></td>];
    for(var i = 0; i < this.props.weeksCount; i++){
      headers.push(<td key={`week${i}`}>Week {i+1}</td>)
    }
    return headers
  }
 
  tableRows(){
    var rows = [];
    var playerNames = this.props.playerNames;
    for(var i = 0; i < playerNames.length; i++){
      rows.push(
        <tr key={`playerNameRow${i}_${playerNames[i]}`}>
          <td key={`playerName_${i}`}>{playerNames[i]}</td>
          {this.playerWeeksRow(playerNames[i])}
        </tr>
      )
    }
    return rows
  }

  playerWeeksRow(playerName) {
    var cols = [];
    for (var i = 1; i <= this.props.weeksCount; i++) {
      var value;
      if (this.props.playerScores[playerName][i-1] == null) {
        value = "";
      }
      else {
        value = this.props.playerScores[playerName][i-1]
      }
      cols.push(
        <td key={`${playerName}_week${i}`} className='player-score'>
          <FormGroup controlId={`${playerName}-${i-1}`} 
            validationState={this.validateScore(playerName, i-1)}>
            <FormControl key={`${playerName}${i}`} type="text" 
              onChange={this.props.handleChange(playerName, i-1)}
              value={value}/> 
            <FormControl.Feedback />
          </FormGroup>
        </td>
      )
    }
    return cols;
  }

  validateScore(playerName, weekIndex) {
    var scoreStr = this.props.playerScores[playerName][weekIndex];
    var scoreEmpty = this.checkIsEmpty(scoreStr);
    var lastScoreEmpty;
    if (weekIndex > 0) {  // Cannot fill in a future discontinuous week
      var lastScoreStr = this.props.playerScores[playerName][weekIndex-1]
      lastScoreEmpty = this.checkIsEmpty(lastScoreStr);
    } else {
      lastScoreEmpty = false;
    }

    if (scoreEmpty) {
      if (weekIndex === 0){
        if (this.checkForValidScoreInWeek(playerName, weekIndex)) {
          return "warning";
        }
        return null;
      }
      if (this.checkForValidScoreInWeek(playerName, weekIndex) && !lastScoreEmpty) {
        return "warning";
      }
      return null;
    } else {
      if (lastScoreEmpty) {
        return "error"
      }
      else {
        return "success"
      }
    }
  }

  checkIsNum(str) {  // Only allow 3 digits before and after period
    return /^[0-9]{1,3}([,.][0-9]{1,3})?$/g.test(str);
  }
  checkIsEmpty(str){
    return (str === "" || str === null || typeof str === 'undefined');
  }
  checkIsEmptyObject(obj) {
    return (Object.entries(obj).length === 0 && obj.constructor === Object)
  }

  // Checks to see if there is a valid entry for another player on weekIndex
  checkForValidScoreInWeek(playerName, weekIndex) {
    for (var i = 0; i < this.props.playerNames.length; i++) {
      var name = this.props.playerNames[i]

      if (name === playerName){
        continue;
      }
      var scoreStr = this.props.playerScores[name][weekIndex]
      var isnum = this.checkIsNum(scoreStr);
      if (isnum) {
        return true;
      }
    }
    return false;
  }

  getMaxWeekCompleted(playerName) {
    if (this.checkIsEmptyObject(this.props.playerScores)) {
      return;
    }
    var scores = this.props.playerScores[playerName];
    for (var j = scores.length-1; j >= 0 ; j--) {
      if (!this.checkIsEmpty(scores[j]) && this.checkIsNum(scores[j])) {
        return j;
      }
    }
  }

  validateForm() {
    var playerScores = this.props.playerScores;
    var maxWeek = this.getMaxWeekCompleted(this.props.playerNames[0]);
    if(typeof maxWeek === 'undefined') {
      return false;
    }
    for (var i = 0; i < this.props.playerNames.length; i++) {
      var scores = playerScores[this.props.playerNames[i]]
      if (maxWeek > scores.length-1) {
        return false;
      }
      for (var j = scores.length-1; j >= 0 ; j--) {
        var score = scores[j]
        if (j <= maxWeek) {
          if (this.checkIsEmpty(score)) {
            return false;
          }
          if (!this.checkIsNum(score)){
            return false;
          }
        }
        else { // j > maxWeek
          if (!this.checkIsEmpty(score)) {
            return false;
          }
        } 
      }
    }
    return true;
  }

  render(){
    return (
      <div>
        <form onSubmit={this.props.handleScoreSubmit}>
          <h2>Scores</h2>
          <Table responsive>
            <thead>
              <tr>
               {this.tableHeaders()}
              </tr>
            </thead>
            <tbody>
              {this.tableRows()}
            </tbody>
          </Table>
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.props.isLoading}
            text="Update"
            loadingText="Updating…"
          />
        </form>
      </div>
    );
  }
}
