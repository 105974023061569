import { API } from "aws-amplify";
import ls from 'local-storage';
import React, { Component, Fragment } from "react";
import { PageHeader, ListGroupItem, ListGroup } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import withAuth from '../contexts/withAuth'
import "./Home.css";
import {get} from 'lodash'

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      notes: [],
      username: "",
      leagues: [],
      memberOf: []
    };
  }

  async componentDidMount() {
    const {account} = this.props
    if (account) {
      const {leagues, memberOf} = account
      if (ls.get('leagues') === null) {
        await this.storeLeagueLocalStorage('leagues', leagues)
        await this.storeLeagueLocalStorage('memberOf', memberOf)
      }
      this.setState({ leagues, memberOf, isLoading: false })
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const {account} = this.props
    if (account && get(prevProps.account, 'accountId', null) !== account.accountId) {
      const {leagues, memberOf} = account
      await this.storeLeagueLocalStorage('leagues', leagues)
      await this.storeLeagueLocalStorage('memberOf', memberOf)
      this.setState({ leagues, memberOf, isLoading: false })
    }
  }

  async getLeague(league) {
    const {account: {accountId}} = this.props
    return API.get("league", `/${league}`, {
      headers: {
        'x-account-id': accountId
      }
    });
  }

  async storeLeagueLocalStorage(key, leagues) {
    const savedLeagues = ls.get(key)
    const leaguesData = await Promise.all(
      leagues.map(async leagueName => {
        const savedLeague = savedLeagues ? savedLeagues[leagueName] : null
        return savedLeague ? savedLeague : await this.getLeague(leagueName)
      })
    )
    const localLeaguesData = leaguesData.reduce((acc, curr) => ({
      ...acc,
      [curr.leagueId]: curr
    }), {})

    ls.set(key, localLeaguesData)
  }

  getCurrentSeason(seasons) {
    if (seasons.length === 0 ) {
      return "None"
    }
    return this.formatSeasonIdStr(seasons[seasons.length-1]);
  }

  formatSeasonIdStr(seasonId) {
    var prefix = String(seasonId).substring(0,2);
    var firstSuffix = String(seasonId).substring(2,4);
    var secondSuffix = String(seasonId).substring(4,6);
    return prefix + firstSuffix + " - " + prefix + secondSuffix;
  }

  renderLeaguesList(leagues) {
    const savedLeagues = ls.get('leagues')
    for(var i = 0; i < leagues.length; i++ ){
      if (savedLeagues[i] === null) {
        return;
      }
    }
    return leagues.map(league_id =>
      <LinkContainer key={league_id} to={`/leagues/${league_id}`} >
        <ListGroupItem header={savedLeagues[league_id]['leagueName']}> Current season: {this.getCurrentSeason(savedLeagues[league_id]['seasons'])}
        </ListGroupItem>
      </LinkContainer>
    );
  }

  renderMemberOfList(leagues) {
    const savedLeagues = ls.get('memberOf')
    for(var i = 0; i < leagues.length; i++ ){
      if (savedLeagues[i] === null) {
        return;
      }
    }
    return leagues.map(league_id =>
      <LinkContainer key={league_id} to={`/leagues/${league_id}`} >
          <ListGroupItem header={savedLeagues[league_id]['leagueName']}> Current season: {this.getCurrentSeason(savedLeagues[league_id]['seasons'])}
          </ListGroupItem>
      </LinkContainer>
    );
  }

  renderLander() {
    return (
      <div className="lander">
          <h1>HistoLeague</h1>
          <p>A fantasy football data visualization app</p>
        </div>
    );
  }

  renderLeagues() {
    const {leagues, memberOf, isLoading} = this.state
    return (
      <div className="leagues">
        {!isLoading &&
          <Fragment>
            <PageHeader>Manager of Leagues</PageHeader>
            <ListGroup>
              <LinkContainer key="new" to="/leagues/new">
                <ListGroupItem>
                  <h4>
                    <b>{"\uFF0B"}</b> Create a new League
                  </h4>
                </ListGroupItem>
              </LinkContainer>
              {this.renderLeaguesList(leagues)}
            </ListGroup>
          </Fragment>
        }
        {!!memberOf.length &&
         <Fragment>
           <PageHeader>Member of Leagues</PageHeader>
           <ListGroup>
             {!isLoading && this.renderMemberOfList(memberOf)}
           </ListGroup>
         </Fragment>
        }
      </div>
    );
  }

  render() {
    const {isAuthorized} = this.props
    return (
      <div className="Home">
        {isAuthorized() ? this.renderLeagues() : this.renderLander()}
      </div>
    );
  }
}

export default withAuth(Home)