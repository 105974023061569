import React, { Component } from "react";
import Plot from 'react-plotly.js';

export default class ScoresPlot extends Component {

  getListOfCompletedWeeks(maxNum) {
    var list = [];
    for (var j = 1; j <= maxNum; j++) {
      list.push(j);
    }
    return list;
  }

  getTracesFromPlayerScores(playerNames, playerScores, completedWeeksCount) {
    var traces = [];
    for (var i = 0; i < playerNames.length; i++) {
      var name = playerNames[i];
      var trace = {}

      trace['x'] = this.getListOfCompletedWeeks(completedWeeksCount)
      trace['y'] = playerScores[name];
      trace['name'] = name;
      trace['type'] = 'scatter'
      traces.push(trace);
    }
    return traces;
  }

  getLayout(playerNames, completedWeeksCount) {
    if (playerNames === undefined || playerNames.length === 0) {
      return;
    }
    var weeks = this.getListOfCompletedWeeks(completedWeeksCount)
    var layout = {
      title: 'Scores', 
      xaxis:{
        title:'Weeks',
        tickvals: weeks
      }, 
      yaxis:{title:'Score'},
      autosize: true,
    }
    return layout
  }

  getStyle() {
    return {
      width: '100%'
    }
  }

  render() {
    return (<Plot 
      data={this.getTracesFromPlayerScores(this.props.playerNames, this.props.playerScores, this.props.completedWeeksCount)}
      layout={this.getLayout(this.props.playerNames, this.props.completedWeeksCount)} useResizeHandler={true}
      style={this.getStyle()}/>)
  }
}