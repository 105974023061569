import React, { Component } from "react";
import Plot from 'react-plotly.js';

export default class AverageOutcomePlot extends Component {

  getTraceFromMetaScores(playerNames, metaScores, completedWeeksCount) {
    if (playerNames === undefined || playerNames.length === 0) {
      return;
    }
    var yValues = [];
    for (var i = 0; i < playerNames.length; i++) {
      var name = playerNames[i];
      var avg = this.calculateAverageOutcome(metaScores[name].pointsFor, metaScores[name].pointsAgainst, completedWeeksCount)
      yValues.push(avg);
    }

    var trace = {
      x: playerNames,
      y: yValues,
      type: 'bar'
    }
    return [trace];
  }

  sortNumber(a, b) {
    return b - a;
  }

  calculateAverageOutcome(pointsFor, pointsAgainst, numWeeks) {
    return ((pointsFor - pointsAgainst)/numWeeks).toFixed(3);
  }

  getLayout(playerNames) {
    if (playerNames === undefined || playerNames.length === 0) {
      return;
    }
    var layout = {
      title: 'Average Outcome', 
      xaxis: playerNames, 
      yaxis:{title:'Score'},
      autosize: true,
    }
    return layout
  }

  getStyle() {
    return {
      width: '100%'
    }
  }

  render() {
    return (<Plot 
      data={this.getTraceFromMetaScores(this.props.playerNames, this.props.metaScores, this.props.completedWeeksCount)}
      layout={this.getLayout(this.props.playerNames, this.props.metaScores)}
      useResizeHandler={true} style={this.getStyle()}/>)
  }
}